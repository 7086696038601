<template>
  <div class="panel-sidebar">
    <ul class="content">
      <div class="logo">
        <a href="javascript:void(0)">
          <span class="pic">
            <img src="/favicon.ico" alt="">
          </span>
          <p>Assistant Bot Web UI</p>
        </a>
      </div>
      <!-- <li v-for="(item, index) in defaultList" :key="index" :class="{active : flag(item.keywords)}">
        <a :href="item.child ? 'javascript:void(0)' : item.value[0].url">{{ item.child ? item.name : item.value[0].name }}</a>
        <ul class="sec-menu" :style="{display: flag(item.keywords) ? 'block' : 'none'}" v-if="item.child">
          <li v-for="(secItem, index) in item.value" :key="index" :class="{active : uri === secItem.url}">
            <a :href="secItem.url" :title="secItem.name">{{ secItem.name }}</a>
          </li>
        </ul>
      </li>
      <template v-if="record">
        <li v-for="(item, index) in recordList" :key="index" :class="{active : flag(item.keywords)}">
          <a :href="item.child ? 'javascript:void(0)' : item.value[0].url">{{ item.child ? item.name : item.value[0].name }}</a>
          <ul class="sec-menu" :style="{display: flag(item.keywords) ? 'block' : 'none'}" v-if="item.child">
            <li v-for="(secItem, index) in item.value" :key="index" :class="{active : uri === secItem.url}">
              <a :href="secItem.url" :title="secItem.name">{{ secItem.name }}</a>
            </li>
          </ul>
        </li>
      </template> -->

      <li :class="{active : flag('-')}">
        <a href="/panel">{{ t('components.panelSidebar.homepage') }}</a>
      </li>
      <li :class="{active : flag('config')}">
        <a href="/panel/config">{{ t('components.panelSidebar.config') }}</a>
      </li>
      <li :class="{active : flag('members_by_all')}">
        <a href="/panel/members_by_all">{{ t('components.panelSidebar.membersByAll') }}</a>
      </li>
      <li :class="{active : flag('members_by_count')}">
        <a href="/panel/members_by_count">{{ t('components.panelSidebar.membersByCount') }}</a>
      </li>
      <li :class="{active : flag('members_by_time')}">
        <a href="/panel/members_by_time">{{ t('components.panelSidebar.membersByTime') }}</a>
      </li>
      <li :class="{active : flag('members_by_quit')}">
        <a href="/panel/members_by_quit">{{ t('components.panelSidebar.membersByQuit') }}</a>
      </li>
      <li :class="{active : flag('top10')}">
        <a href="/panel/top10">{{ t('components.panelSidebar.top10') }}</a>
      </li>
      <template v-if="record">
        <li :class="{active : flag('daily')}">
          <a href="/panel/daily">{{ t('components.panelSidebar.daily') }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { computed, reactive, toRefs } from 'vue'

export default {
  name: 'PanelSidebar',
  setup() {
    const { t } = useI18n()
    const Store = useStore()
    const Route = useRoute()
    const data = reactive({
      uri: Route.path,
      record: computed(() => Store.getters.get('record')),
      // list: [
      //   {
      //     name: '开发者面板',
      //     child: true,
      //     keywords: 'dev',
      //     value: [
      //       { name: '所有群组', url: '/panel/dev/groups' },
      //       { name: '登陆查询', url: '/panel/dev/login' },
      //       { name: '其他功能', url: '/panel/dev/others' }
      //     ]
      //   }
      // ],
      defaultList: [
        {
          name: '',
          child: false,
          keywords: '-',
          value: [{ name: t('components.panelSidebar.homepage'), url: '/panel' }]
        },
        {
          name: '',
          child: false,
          keywords: 'config',
          value: [{ name: t('components.panelSidebar.config'), url: '/panel/config' }]
        },
        {
          name: '',
          child: false,
          keywords: 'members_by_all',
          value: [{ name: t('components.panelSidebar.membersByAll'), url: '/panel/members_by_all' }]
        }
      ],
      recordList: [
        {
          name: '',
          child: false,
          keywords: 'members_by_count',
          value: [{ name: t('components.panelSidebar.membersByCount'), url: '/panel/members_by_count' }]
        },
        {
          name: '',
          child: false,
          keywords: 'members_by_time',
          value: [{ name: t('components.panelSidebar.membersByTime'), url: '/panel/members_by_time' }]
        },
        {
          name: '',
          child: false,
          keywords: 'members_by_quit',
          value: [{ name: t('components.panelSidebar.membersByQuit'), url: '/panel/members_by_quit' }]
        },
        {
          name: '',
          child: false,
          keywords: 'top10',
          value: [{ name: t('components.panelSidebar.top10'), url: '/panel/top10' }]
        },
        {
          name: '',
          child: false,
          keywords: 'daily',
          value: [{ name: t('components.panelSidebar.daily'), url: '/panel/daily' }]
        }
      ]
    })

    const flag = str => {
      return RegExp(str).test(data.uri)
    }

    return { ...toRefs(data), t, flag }
  }
}
</script>

<style lang="scss" scoped>
.panel-sidebar {
  width: 280px;
  position: fixed;
  top: 60px;
  bottom: 0;
  background: #2f323a;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform 0.2s;
  .content {
    padding: 30px 10px 20px;
    .logo {
      text-align: center;
      margin-bottom: 30px;
      a {
        color: #fff;
        display: inline-block;
        span {
          display: inline-block;
          width: 80px;
        }
        p {
          display: block;
          padding-top: 10px;
        }
      }
    }
    li {
      line-height: 45px;
      a {
        color: #aeb2b7;
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 0 10px;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    > .active {
      > a {
        color: #fff;
        background: #60b7ff;
      }
    }
    > li:hover {
      > a {
        color: #fff;
        background: #60b7ff;
      }
    }
    .sec-menu {
      display: none;
      margin-left: 1.5em;
      > .active {
        a {
          color: #fff;
          background: #ff6060;
        }
      }
      > li:hover {
        a {
          color: #fff;
          background: #ff6060;
        }
      }
    }
    li:hover {
      .sec-menu {
        display: block !important;
      }
    }
  }
}
</style>
