/**
 * unix 时间转字符串
 * @param {Number} unix 时间戳
 */
export function unixToDate(unix) {
  if (unix <= 0) return ''
  const date = new Date(unix)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return Y + M + D
}

/**
 * 秒转分钟
 * @param {Number} seconds 秒
 */
export function secToMin(seconds) {
  return seconds / 60
}

/**
 * 秒转小时
 * @param {Number} seconds 秒
 */
export function secToHour(seconds) {
  return seconds / 60 / 60
}
