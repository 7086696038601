<template>
  <div class="navigation">
    <div class="group">
      <div class="info">
        <a class="menu" href="javascript:void(0)" v-if="uri != '/panel'" @click="sidebar()"><em></em></a>
        <a class="name" href="/panel">
          <span class="pic"><em :style="{backgroundImage: `url(${groupAvatar})`}"></em></span>
          <span class="name">{{ groupName }}</span>
        </a>
      </div>
    </div>
    <div class="user">
      <div class="info">
        <p>
          <span class="avatar"><em :style="{backgroundImage: `url(${userAvatar})`}"></em></span>
          <span class="nickname">{{ nickname }}</span>
          <span class="icon"><em></em></span>
        </p>
        <div class="bottom-slide">
          <ul>
            <li class="language" @click="changeLanguage()">
              <a href="javascript:void(0)">
                <p>
                  <span><img src="../assets/img/lang.svg" alt=""></span>
                  <span>{{ t('components.panelNav.changeLanguage') }}</span>
                </p>
              </a>
            </li>
            <li class="logout" @click="logout()">
              <a href="javascript:void(0)">
                <p>
                  <span><img src="../assets/img/logout.svg" alt=""></span>
                  <span>{{ t('components.panelNav.logout') }}</span>
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useDialog } from '../utils/dialog.js'
import { useRoute, useRouter } from 'vue-router'
import { reactive, toRefs, onMounted, computed } from 'vue'
import { updateLanguageApi, logoutApi } from '../api/index.js'

export default {
  name: 'PanelNav',
  setup() {
    const { t, locale } = useI18n()
    const Store = useStore()
    const Route = useRoute()
    const Router = useRouter()
    const Dialog = useDialog()
    const data = reactive({
      uri: Route.path,

      languageList: Store.getters.get('language'),
      userLanguage: Store.getters.get('userLanguage'),

      groupName: '',
      groupAvatar: '',
      nickname: Store.getters.getPanelNav('nickname'),
      userAvatar: Store.getters.getPanelNav('userAvatar')
    })

    onMounted(() => {
      if (data.uri === '/panel') {
        data.groupName = 'Assistant Bot Web UI'
        data.groupAvatar = '/favicon.ico'
      } else {
        data.groupName = computed(() => Store.getters.getPanelNav('groupName'))
        data.groupAvatar = computed(() => Store.getters.getPanelNav('groupAvatar'))
      }
    })

    const sidebar = () => {
      const menu = document.getElementsByClassName('panel-sidebar')[0]
      const content = document.getElementsByClassName('panel-content')[0]
      if (menu.style.transform !== 'translate3d(-100%, 0px, 0px)') {
        menu.style.transform = 'translate3d(-100%, 0px, 0px)'
        content.style.left = '0px'
      } else {
        menu.style.transform = 'translate3d(0px, 0px, 0px)'
        content.style.left = '280px'
      }
    }

    const changeLanguage = () => {
      const select = document.createElement('select')
      select.style.width = '100%'
      select.style.height = '35px'
      select.style.margin = '10px 0'
      select.style.padding = '0 5px'
      select.style.background = '#ddd'
      select.style.borderRadius = '4px'
      select.addEventListener('change', function() {
        data.userLanguage = data.languageList[select.selectedIndex]
      })
      for (let i = 0; i < data.languageList.length; i++) {
        const option = document.createElement('option')
        option.value = data.languageList[i]
        option.innerText = t(`language.${data.languageList[i].replace('-', '_')}`)
        if (data.languageList[i] === data.userLanguage) option.selected = true
        select.append(option)
      }
      Dialog({
        title: t('components.panelNav.changeLanguageWin.title'),
        content: select,
        btns: [{ text: t('ok'), click: () => updateLanguage() }, { text: t('cancel') }]
      })
    }

    const updateLanguage = () => {
      Dialog({ type: 'loading' })
      updateLanguageApi({
        id: Store.getters.get('uid'),
        language: data.userLanguage
      }).then(succ => {
        let msg = t('components.panelNav.updateLanguageApi.failed')
        if (succ.code === 10000) {
          msg = t('components.panelNav.updateLanguageApi.success')
          Store.commit('set', { userLanguage: data.userLanguage })
          localStorage.setItem('lang', data.userLanguage)
          locale.value = data.userLanguage.replace('-', '_')
        }
        Dialog({
          content: msg,
          btns: [{ text: t('ok') }]
        })
      })
    }

    const logout = () => {
      Dialog({ type: 'loading' })
      logoutApi().then(() => {
        Router.push({ name: 'Login' })
      }).finally(() => {
        Dialog({ remove: true })
      })
    }

    return { ...toRefs(data), t, sidebar, changeLanguage, logout }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  display: table;
  table-layout: fixed;
  width: 100%;
  min-width: 1100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 10px 30px 10px 10px;
  background: #22242a;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.2);
  > div {
    display: table-cell;
    vertical-align: middle;
  }
  .group {
    padding-right: 60px;
    .info {
      width: 100%;
      display: table;
      table-layout: fixed;
      a, i {
        display: table-cell;
        vertical-align: middle;
      }
      .menu {
        width: 30px;
        em {
          display: block;
          width: 30px;
          height: 30px;
          background: no-repeat center center / cover;
          background-image: url('../assets/img/menu.svg');
        }
      }
      .name {
        color: #fff;
        display: table;
        table-layout: fixed;
        width: 100%;
        border: none;
        margin-left: 20px;
        span {
          display: table-cell;
          font-size: 20px;
          font-weight: bold;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pic {
          width: 40px;
          em {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: no-repeat center center / cover gray;
          }
        }
        .name {
          padding: 0 12px;
          white-space: nowrap;
        }
      }
    }
  }
  .user {
    width: 300px;
    .info {
      display: inline-block;
      position: relative;
      p {
        color: #fff;
        width: 100%;
        display: table;
        table-layout: fixed;
        cursor: pointer;
        span {
          display: table-cell;
          vertical-align: middle;
          em {
            display: block;
            background: no-repeat center center / cover;
          }
        }
        .avatar {
          width: 30px;
          em {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: gray;
          }
        }
        .nickname {
          padding: 0 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon {
          width: 14px;
          em {
            width: 14px;
            height: 14px;
            background-image: url('../assets/img/down.svg');
          }
        }
      }
      .bottom-slide {
        display: none;
        width: 300px;
        padding-top: 17px;
        position: absolute;
        right: 0;
        ul {
          overflow: hidden;
          border-radius: 6px;
          text-align: center;
          background: #f7f7f7;
          box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
          li {
            line-height: 40px;
            font-size: 15px;
            overflow: hidden;
            a {
              display: block;
              p {
                display: table;
                width: 100%;
                padding-left: 10px;
                white-space: nowrap;
                span {
                  color: #222;
                  display: table-cell;
                  vertical-align: middle;
                }
                span:first-child {
                  width: 24px;
                }
              }
            }
            a:hover p {
              background: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
    .info:hover .bottom-slide {
      display: block;
    }
  }
}
</style>
